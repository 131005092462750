<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <!--<a-col :md="6" :sm="24">-->
              <!--<a-form-item :label="$t('砍价活动配置.店铺id')" prop="shopId">-->
                <!--<a-input v-model="queryParam.shopId" :maxLength="32" :placeholder="$t('通用.输入.请输入')+$t('砍价活动配置.店铺id')" allow-clear/>-->
              <!--</a-form-item>-->
            <!--</a-col>-->

            <a-col :md="6" :sm="24">
              <a-form-item :label="$t('砍价活动配置.活动名称')" prop="acName">
                <a-input v-model="queryParam.acName" :maxLength="32" :placeholder="$t('通用.输入.请输入')+$t('砍价活动配置.活动名称')" allow-clear/>
              </a-form-item>
            </a-col>
            <template v-if="advanced">
              <a-col :md="6" :sm="24">
                <a-form-item :label="$t('砍价活动配置.活动开始时间')" prop="acStartTime">
                  <a-date-picker style="width: 100%" v-model="queryParam.acStartTime" format="YYYY-MM-DD HH:mm:ss" allow-clear/>
                </a-form-item>
              </a-col>
              <a-col :md="6" :sm="24">
                <a-form-item :label="$t('砍价活动配置.活动结束时间')" prop="acEndTime">
                  <a-date-picker style="width: 100%" v-model="queryParam.acEndTime" format="YYYY-MM-DD HH:mm:ss" allow-clear/>
                </a-form-item>
              </a-col>
              <!--<a-col :md="6" :sm="24">-->
                <!--<a-form-item :label="$t('砍价活动配置.商品图片')" prop="goodsPicture">-->
                  <!--<a-input v-model="queryParam.goodsPicture" :maxLength="32" :placeholder="$t('通用.输入.请输入')+$t('砍价活动配置.商品图片')" allow-clear/>-->
                <!--</a-form-item>-->
              <!--</a-col>-->
              <a-col :md="6" :sm="24">
                <a-form-item :label="$t('砍价活动配置.商品名称')" prop="goodsName">
                  <a-input v-model="queryParam.goodsName" :maxLength="32" :placeholder="$t('通用.输入.请输入')+$t('砍价活动配置.商品名称')" allow-clear/>
                </a-form-item>
              </a-col>



              <!--<a-col :md="6" :sm="24">-->
                <!--<a-form-item :label="$t('砍价活动配置.审核意见')" prop="auditMessage">-->
                  <!--<a-input v-model="queryParam.auditMessage" :maxLength="32" :placeholder="$t('通用.输入.请输入')+$t('砍价活动配置.审核意见')" allow-clear/>-->
                <!--</a-form-item>-->
              <!--</a-col>-->

              <!--<a-col :md="6" :sm="24">-->
                <!--<a-form-item :label="$t('砍价活动配置.多少人参与砍价即可成功')" prop="userNumber">-->
                  <!--<a-input v-model="queryParam.userNumber" :maxLength="32" :placeholder="$t('通用.输入.请输入')+$t('砍价活动配置.多少人参与砍价即可成功')" allow-clear/>-->
                <!--</a-form-item>-->
              <!--</a-col>-->
              <!--<a-col :md="6" :sm="24">-->
                <!--<a-form-item :label="$t('砍价活动配置.砍价活动价格')" prop="acPrice">-->
                  <!--<a-input v-model="queryParam.acPrice" :maxLength="32" :placeholder="$t('通用.输入.请输入')+$t('砍价活动配置.砍价活动价格')" allow-clear/>-->
                <!--</a-form-item>-->
              <!--</a-col>-->

              <!--<a-col :md="6" :sm="24">-->
                <!--<a-form-item :label="$t('砍价活动配置.该活动完成砍价数量')" prop="completeTotal">-->
                  <!--<a-input v-model="queryParam.completeTotal" :maxLength="32" :placeholder="$t('通用.输入.请输入')+$t('砍价活动配置.该活动完成砍价数量')" allow-clear/>-->
                <!--</a-form-item>-->
              <!--</a-col>-->


              <!--<a-col :md="6" :sm="24">-->
                <!--<a-form-item :label="$t('砍价活动配置.砍价有效期(单位:小时)多少小时之内砍价没完成,就为砍价失败')" prop="validTime">-->
                  <!--<a-input v-model="queryParam.validTime" :maxLength="32" :placeholder="$t('通用.输入.请输入')+$t('砍价活动配置.砍价有效期(单位:小时)多少小时之内砍价没完成,就为砍价失败')" allow-clear/>-->
                <!--</a-form-item>-->
              <!--</a-col>-->

            </template>
            <a-col :md="!advanced && 6 || 24" :sm="24">
              <span class="table-page-search-submitButtons" :style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
                <a-button type="primary" @click="handleQuery"><a-icon type="search" />{{$t('通用.按钮.查询')}}</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />{{$t('通用.按钮.重置')}}</a-button>
                <a @click="toggleAdvanced" style="margin-left: 8px">
                  {{ advanced ? $t('通用.按钮.收起') : $t('通用.按钮.展开') }}
                  <a-icon :type="advanced ? 'up' : 'down'"/>
                </a>
              </span>
            </a-col>
          </a-row>
        </a-form>
        <a-modal :visible="previewImageVisible" :footer="null" @cancel="previewImageCancel">
          <img alt="example" style="width: 100%" :src="previewUrl" />
        </a-modal>
      </div>
      <!-- 操作 -->
      <div class="table-operations">
        <a-button type="primary" @click="$refs.createForm.handleAdd()" v-hasPermi="['zeroActivityProd:zeroActivityProd:add']">
          <a-icon type="plus" />{{$t('通用.按钮.新增')}}
        </a-button>
        <a-button type="primary" :disabled="single" @click="$refs.createForm.handleUpdate(undefined, ids)" v-hasPermi="['zeroActivityProd:zeroActivityProd:edit']">
          <a-icon type="edit" />{{$t('通用.按钮.修改')}}
        </a-button>
        <a-button type="danger" :disabled="multiple" @click="handleDelete" v-hasPermi="['zeroActivityProd:zeroActivityProd:remove']">
          <a-icon type="delete" />{{$t('通用.按钮.删除')}}
        </a-button>
        <a-button type="primary" @click="handleExport" v-hasPermi="['zeroActivityProd:zeroActivityProd:export']">
          <a-icon type="download" />{{$t('通用.按钮.导出')}}
        </a-button>
        <table-setting
          :style="{float: 'right'}"
          :table-size.sync="tableSize"
          v-model="columns"
          :refresh-loading="loading"
          @refresh="getList" />
      </div>
      <!-- 增加修改 -->
      <create-form
        ref="createForm"
        @ok="getList"
      />
      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        :size="tableSize"
        @change="handleSortChange"
        rowKey="id"
        :columns="columns"
        :data-source="list"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        :pagination="false"
        :bordered="tableBordered"
      >

        <span slot="checkStatus" slot-scope="text, record">
           <!--<custom-dict-tag :options="customDict.SECKILLENUM" :value="record.checkStatus"/>-->
           <span v-if="record.checkStatus == '1'">
             {{$t('审核状态.待审核')}}
           </span>
           <span v-if="record.checkStatus == '2'">
             {{$t('审核状态.审核失败')}}
           </span>
           <span v-if="record.checkStatus == '3'">
             {{$t('审核状态.审核成功')}}
           </span>
           <span v-if="record.checkStatus == '4'">
             {{$t('审核状态.过期未审核')}}
           </span>
           <span v-if="record.checkStatus == '5'">
             {{$t('审核状态.活动已结束')}}
           </span>
        </span>

        <span slot="acStartTime" slot-scope="text, record">
          {{ parseTime(record.acStartTime) }}
        </span>
        <span slot="acEndTime" slot-scope="text, record">
          {{ parseTime(record.acEndTime) }}
        </span>
        <span slot="createTime" slot-scope="text, record">
          {{ parseTime(record.createTime) }}
        </span>
        <span slot="operation" slot-scope="text, record">
          <a-divider type="vertical" v-hasPermi="['zeroActivityProd:zeroActivityProd:edit']" />
          <a @click="$refs.createForm.handleUpdate(record, undefined)" v-hasPermi="['zeroActivityProd:zeroActivityProd:edit']">
             <a-icon type="edit" />{{$t('通用.按钮.修改')}}
          </a>
          <a-divider type="vertical" v-hasPermi="['zeroActivityProd:zeroActivityProd:remove']" />
          <a @click="handleDelete(record)" v-hasPermi="['zeroActivityProd:zeroActivityProd:remove']">
            <a-icon type="delete" />{{$t('通用.按钮.删除')}}
          </a>


           <a-divider type="vertical"/>
          <a-popconfirm title="确定要审核通过吗？" ok-text="确定" cancel-text="取消" @confirm="pass(record)" >
          <a href="javascript:void(0)" v-if="record.checkStatus == 1">审核通过</a>
          </a-popconfirm>
          <a href="javascript:void(0)" v-if="record.checkStatus == 1" class="text-red margin-left-sm" @click="refuseModal(record)">审核拒绝</a>


        </span>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :pageSizeOptions="pageSizeOptions"
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="total => getTotal(total)"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </a-card>

    <a-modal title="审核" :visible="visible" :confirm-loading="confirmLoading" @ok="refuse" @cancel="visible = false">
      <a-input v-model="auditMessage" placeholder="填写审核意见" type="textarea" />
    </a-modal>

  </page-header-wrapper>
</template>

<script>
import { pageZeroActivityProd,listZeroActivityProd, delZeroActivityProd,auditZeroActivityProd } from '@/api/zeroActivityProd/zeroActivityProd'
import CustomDictTag from "@/components/DictCustomTag";
import {mapGetters} from 'vuex'
import moment from 'moment';
import CreateForm from './modules/CreateForm'
import { tableMixin } from '@/store/table-mixin'

export default {
  name: 'ZeroActivityProd',
  components: {
    CustomDictTag,
    CreateForm
  },
  mixins: [tableMixin],
  data () {
    return {
      auditMessage: '',
      visible: false,
      confirmLoading: false,
      list: [],
      pageSizeOptions: ['10', '20', '50', '100'],
      filteredInfo: {},
      sortedInfo : {},
      selectedRowKeys: [],
      selectedRows: [],
      //预览封面
      previewImageVisible: false,
      previewUrl: false,
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 查询参数
      queryParam: {
        shopId: undefined,
        acName: undefined,
        acStartTime: undefined,
        acEndTime: undefined,
        goodsId: undefined,
        goodsPicture: undefined,
        goodsName: undefined,
        goodsPrice: undefined,
        checkStatus: undefined,
        auditMessage: undefined,
        userNumber: undefined,
        acPrice: undefined,
        completeTotal: undefined,
        validTime: undefined,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        // {
        //   title: this.$t('砍价活动配置.id'),
        //   dataIndex: 'id',
        //   ellipsis: true,
        //   align: 'center'
        // },
        // {
        //   title: this.$t('砍价活动配置.店铺id'),
        //   dataIndex: 'shopId',
        //   ellipsis: true,
        //   align: 'center'
        // },
        {
          title: this.$t('砍价活动配置.活动名称'),
          dataIndex: 'acName',
          ellipsis: true,
          align: 'center'
        },
        {
          title: this.$t('砍价活动配置.活动开始时间'),
          dataIndex: 'acStartTime',
          scopedSlots: { customRender: 'acStartTime' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: this.$t('砍价活动配置.活动结束时间'),
          dataIndex: 'acEndTime',
          scopedSlots: { customRender: 'acEndTime' },
          ellipsis: true,
          align: 'center'
        },
        // {
        //   title: this.$t('砍价活动配置.商品id'),
        //   dataIndex: 'goodsId',
        //   ellipsis: true,
        //   align: 'center'
        // },
        // {
        //   title: this.$t('砍价活动配置.商品图片'),
        //   dataIndex: 'goodsPicture',
        //   ellipsis: true,
        //   align: 'center'
        // },
        {
          title: this.$t('砍价活动配置.商品名称'),
          dataIndex: 'goodsName',
          ellipsis: true,
          align: 'center'
        },
        // {
        //   title: this.$t('砍价活动配置.商品原价'),
        //   dataIndex: 'goodsPrice',
        //   ellipsis: true,
        //   align: 'center'
        // },
        {
          title: this.$t('砍价活动配置.审核状态'),
          dataIndex: 'checkStatus',
          ellipsis: true,
          align: 'center',
          scopedSlots: {customRender: 'checkStatus'},
        },
        // {
        //   title: this.$t('砍价活动配置.审核意见'),
        //   dataIndex: 'auditMessage',
        //   ellipsis: true,
        //   align: 'center'
        // },
        {
          title: this.$t('砍价活动配置.多少人参与砍价即可成功'),
          dataIndex: 'userNumber',
          ellipsis: true,
          align: 'center'
        },
        {
          title: this.$t('砍价活动配置.砍价活动价格'),
          dataIndex: 'acPrice',
          ellipsis: true,
          align: 'center'
        },
        {
          title: this.$t('砍价活动配置.该活动完成砍价数量'),
          dataIndex: 'completeTotal',
          ellipsis: true,
          align: 'center'
        },
        {
          title: this.$t('砍价活动配置.砍价有效期'),
          dataIndex: 'validTime',
          ellipsis: true,
          align: 'center'
        },
        // {
        //   title: this.$t('砍价活动配置.创建时间'),
        //   dataIndex: 'createTime',
        //   scopedSlots: { customRender: 'createTime' },
        //   ellipsis: true,
        //   align: 'center'
        // },
        // {
        //   title: this.$t('砍价活动配置.备注'),
        //   dataIndex: 'remark',
        //   ellipsis: true,
        //   align: 'center'
        // },
        {
          title: this.$t('通用.文本.操作'),
          dataIndex: 'operation',
          width: '18%',
          scopedSlots: { customRender: 'operation' },
          align: 'center'
        }
      ]
    }
  },
  filters: {
  },
  created () {
    this.getList()
  },
  computed: {
    ...mapGetters(['customDict'])
  },
  watch: {
  },
  methods: {
    moment,
    getTotal(total) {
      return this.$t('通用.文本.共')+total+this.$t('通用.文本.条')
    },
    /** 查询砍价活动配置列表 */
    getList () {
      this.loading = true
     pageZeroActivityProd(this.queryParam).then(response => {
        this.list = response.data.records
        this.total = response.data.total
        this.loading = false
      })
    },

    /** 搜索按钮操作 */
    handleQuery () {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery () {
      this.queryParam = {
        shopId: undefined,
        acName: undefined,
        acStartTime: undefined,
        acEndTime: undefined,
        goodsId: undefined,
        goodsPicture: undefined,
        goodsName: undefined,
        goodsPrice: undefined,
        checkStatus: undefined,
        auditMessage: undefined,
        userNumber: undefined,
        acPrice: undefined,
        completeTotal: undefined,
        validTime: undefined,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    handleSortChange(pagination, filters, sorter) {
      this.filteredInfo = filters
      this.sortedInfo = sorter
      if (sorter.order) {
        /** 排序字段 sortField **/
        /** 排序方式  sortOrder**/
        this.queryParam.sortField = sorter.columnKey
        if (this.queryParam.sortField==='createTime') {
          this.queryParam.sortField= 'id'
        }
        this.queryParam.sortOrder = sorter.order === 'ascend' ? 'asc' : 'desc'
        this.getList()
      } else {
        this.queryParam.sortField = '';
        this.queryParam.sortOrder = ''
        this.getList()
      }
    },
    onShowSizeChange (current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize (current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map(item => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    previewImageCancel() {
      this.previewImageVisible = false;
    },
    handleRecordPreview(url) {
      this.previewUrl = url
      this.previewImageVisible = true
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },
    /** 删除按钮操作 */
    handleDelete (row) {
      var that = this
      const ids = row.id || this.ids
      if(row.id){
        this.ids = []
        this.ids.push(row.id)
      }
      this.$confirm({
        title: this.$t('通用.文本.确认删除所选中数据'),
        onOk () {
          let data = []
          that.ids.map(id => data.push({"id":id}))
          return delZeroActivityProd(data)
            .then(() => {
              that.onSelectChange([], [])
              that.getList()
              const message = that.$t('通用.文本.删除成功')
              that.$message.success(
                message,
                3
              )
          })
        },
        onCancel () {
          //取消清空删除ids列表
          that.ids = []
        }
      })
    },
    /** 导出按钮操作 */
    handleExport () {
      var that = this
      this.$confirm({
        title: this.$t('通用.文本.是否确认导出'),
        content: this.$t('通用.文本.此操作将导出当前条件下所有数据而非选中数据'),
        onOk () {
          that.download('zeroActivityProd/zeroActivityProd/export',
            ...that.queryParam
          , `砍价活动配置_${new Date().getTime()}.xlsx`)
        },
        onCancel () {}
      })
    },
    refuseModal(info) {
      this.visible = true
      this.curInfo = info
    },
    refuse(info, onOff) {
      this.confirmLoading = true
      auditZeroActivityProd({
        id: this.curInfo.id,
        checkStatus: 2,
        auditMessage: this.auditMessage
      }).then(response => {
        this.visible = false
      this.confirmLoading = false
      if (response.success) {
        this.$message.success('操作成功')
        this.getList()
      } else {
        this.$message.error(res.data.message)
      }
    }).catch(err => {
        this.visible = false
    })
    },

    pass(info, onOff) {
      auditZeroActivityProd({
        id: info.id,
        checkStatus: 3,
      }).then(response => {
        if (response.success) {
        this.$message.success(this.$t('通用.文本.操作成功'));
        this.getList();
      } else {
        this.$message.error(res.data.message)
      }
    })
    },


  }
}
</script>
