import request from '@/utils/request'


// 查询砍价活动配置列表
export function listZeroActivityProd(query) {
  return request({
    url: '/zeroActivityProd/zeroActivityProd/list',
    method: 'get',
    params: query
  })
}

// 查询砍价活动配置分页
export function pageZeroActivityProd(query) {
  return request({
    url: '/zeroActivityProd/zeroActivityProd/page',
    method: 'get',
    params: query
  })
}

// 查询砍价活动配置详细
export function getZeroActivityProd(data) {
  return request({
    url: '/zeroActivityProd/zeroActivityProd/detail',
    method: 'get',
    params: data
  })
}

// 新增砍价活动配置
export function addZeroActivityProd(data) {
  return request({
    url: '/zeroActivityProd/zeroActivityProd/add',
    method: 'post',
    data: data
  })
}

// 修改砍价活动配置
export function updateZeroActivityProd(data) {
  return request({
    url: '/zeroActivityProd/zeroActivityProd/edit',
    method: 'post',
    data: data
  })
}

// 删除砍价活动配置
export function delZeroActivityProd(data) {
  return request({
    url: '/zeroActivityProd/zeroActivityProd/delete',
    method: 'post',
    data: data
  })
}

// 审核砍价活动商品
export function auditZeroActivityProd(data) {
  return request({
    url: '/zeroActivityProd/zeroActivityProd/audit',
    method: 'post',
    data: data
  })
}